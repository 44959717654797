import React, { useEffect, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import axiosInstance from '../../../js/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const UserList = () => {
  const [adminList, setAdminList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get('/get-admins')
        if (response.data.status === 200) {
          setTimeout(() => {
            setAdminList(response.data.data)
            setIsLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.error('Error fetching admin data:', error)
        toast.error('Error fetching admin data')
        setIsLoading(false);
      }
    }

    fetchAdminData()
  }, [])

  // const handleDeleteAdmin = async (adminId) => {
  //   try {
  //     const response = await axiosInstance.delete(`/delete-admin/${adminId}`)
  //     if (response.data.success) {
  //       toast.success('Admin deleted successfully')
  //       // If needed, you can update the admin list after deletion
  //       setAdminList((prevAdminList) =>
  //         prevAdminList.filter((admin) => admin.id !== adminId),
  //       )
  //     } else {
  //       toast.error('Error deleting admin')
  //     }
  //   } catch (error) {
  //     console.error('Error deleting admin:', error)
  //     toast.error('Error deleting admin')
  //   }
  // }

  return (
    <>
      <div>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Admin List</h4>
                </div>
                <div className="header-title">
                  <Link
                    to="/dashboard/admin-add"
                    className="btn btn-btn btn-primary"
                  >
                    Create Admin
                  </Link>
                </div>
              </Card.Header>
              <Card.Body className="px-0" style={{ position: 'relative' }}>
                <div className="table-responsive">
                  {isLoading && (
                    <>
                      {/* Desktop Spinner */}
                      <div className="d-none d-md-block">
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'rgba(255, 255, 255, 0.8)',
                            zIndex: 1,
                            marginTop: '70px',
                          }}
                        >
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </div>

                      {/* Mobile Spinner */}
                      <div className="d-md-none">
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'rgba(255, 255, 255, 0.8)',
                            zIndex: 1,
                            marginTop: '70px',
                          }}
                        >
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </div>
                    </>
                  )}
                  <table
                    id="user-list-table"
                    className="table table-striped"
                    role="grid"
                    data-toggle="data-table"
                  >
                    <thead>
                      <tr className="ligth">
                        <th>No.</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Type</th>
                        {/* <th min-width="100px">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {adminList.map((admin, index) => (
                        <tr key={admin._id}>
                          <td>{index + 1}</td>
                          <td>{admin.full_name}</td>
                          <td>{admin.mobile}</td>
                          <td>{admin.email}</td>
                          <td>{admin.type}</td>
                          {/* <td>
                            <div className="flex align-items-center list-user-action">
                              <Link
                                className="btn btn-sm btn-icon btn-success"
                                to={`/dashboard/admin-profile?id=${admin._id}`}
                              >
                                <span className="btn-inner">
                                  <svg
                                    width="32"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z"
                                      stroke="currentColor"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z"
                                      stroke="currentColor"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </Link>
                              <button
                                style={{ marginLeft: '10px' }}
                                className="btn btn-sm btn-icon btn-danger"
                                onClick={() => handleDeleteAdmin(admin.id)}
                              >
                                <span className="btn-inner">
                                  <svg
                                    width="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke="currentColor"
                                  >
                                    <path
                                      d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M20.708 6.23975H3.75"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </div>
      {/* Add ToastContainer at the end of your component */}
      <ToastContainer />
    </>
  )
}

export default UserList
