import React from 'react'
import LogoImg from '../../../assets/images/vartika.png'
const Logo = (props) => {

    return (
        <>
            <img src={LogoImg} alt='Logo' width={'120'} />
        </>
    )
}

export default Logo
